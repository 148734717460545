


































































import { MAP_PROJECT_ACTION } from '@/utils';
import { Component, Vue } from "vue-property-decorator";
import { readProject } from "@/store/main/getters";
import { dispatchSendProjectAction, dispatchGetProject } from "@/store/main/actions";

@Component
export default class Action extends Vue {
  public actions = MAP_PROJECT_ACTION;
  public action = this.actions[0]['key'];
  public dialog = false;
  public loading = true;

  get project() {
    return readProject(this.$store);
  }

  public cancel() {
    this.$router.back();
  }

  public async mounted() {
    await dispatchGetProject(this.$store, Number(this.$router.currentRoute.params.id));
    this.loading = false;
  }

  public async submit() {
    await dispatchSendProjectAction(this.$store, {
      projectId: Number(this.$router.currentRoute.params.id),
      action: this.action,
    });
    this.$router.back();
  }
}
